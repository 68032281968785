import React, { useState, useRef, useEffect } from 'react';
import { Button, AppBar, LinearProgress } from '@mui/material';
import { navigate } from 'gatsby-link';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { useQueryParam } from 'gatsby-query-params';

import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Alert from '@mui/material/Alert';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

import * as dataApi from '../../api/dataApi';

import * as userAuthApi from '../../api/userAuthApi';
import * as webSocketActions from '../../redux/actions/webSocketActions';
import * as serviceAddressActions from '../../redux/actions/serviceAddressActions';
import * as offerCalc from '../../api/offerCalc';

import { getOfferList, getOfferOptionConfig } from './PlanBuilder';
import CheckoutForm, { defaultUiControl } from './CheckoutForm';
import ProviderLogo from '../OfferGrid/ProviderLogo';
import Dashboard from '../Dashboard';

import './index.css';

function CheckoutInternet({
  actions,
  serviceAddress,
  mode,
  stateList,
  siteConfig,
  productTypeID,
  productTypeName,
  modal
}) {
  const [apiGrouping, setApiGrouping] = useState(null);
  const [selectedGrouping, setSelectedGrouping] = useState(null);
  const [offerOptionDictionary, setOfferOptionDictionary] = useState(null);
  const [offerOptionSettings, setOfferOptionSettings] = useState(null);
  const [selectedSection, setSelectedSection] = useState(null);
  const [orderDetailsExpanded, setOrderDetailsExpanded] = useState(null);
  const [iFrameLoaded, setIFrameLoaded] = useState(null);
  const [personalInfo, setPersonalInfo] = useState(null);
  const [uiControl, setUiControl] = useState(defaultUiControl);
  const [requiredProductList, setRequiredProductList] = useState(null);
  const [activeCustomerSelection, setActiveCustomerSelection] = useState(null);

  useEffect(() => {
    const offerOptions = getOfferOptionConfig(
      getOfferList(serviceAddress.OfferList)
    );

    setOfferOptionSettings(offerOptions.settings);
    setOfferOptionDictionary(offerOptions.options);

    if (!offerOptions.settings || !Object.keys(offerOptions.settings).length) {
      navigate('/plan-builder');
    }
  }, [serviceAddress]);

  useEffect(() => {
    if (
      selectedGrouping &&
      apiGrouping &&
      serviceAddress &&
      serviceAddress.Cart
    ) {
      if (
        apiGrouping[selectedGrouping] &&
        apiGrouping[selectedGrouping].offer
      ) {
        //Make sure the selected grouping isn't already complete
        const _cartItem = serviceAddress.Cart.filter(
          (cartItem) =>
            cartItem.OfferID === apiGrouping[selectedGrouping].offer.OfferID
        )[0];
        if (_cartItem && _cartItem.IsCompleted) {
          let _found = false;
          let nextGroupingKey = null;
          Object.keys(apiGrouping).map((groupingKey) => {
            if (_found && !nextGroupingKey) {
              nextGroupingKey = groupingKey;
            }
            if (groupingKey === selectedGrouping) {
              _found = true;
            }
          });

          if (nextGroupingKey) {
            console.log('nextGroupingKey', nextGroupingKey);
            setSelectedGrouping(nextGroupingKey);
          }
        }
      }
    }
  }, [serviceAddress, selectedGrouping]);

  useEffect(() => {
    if (offerOptionDictionary) {
      let _apiGrouping = {};
      let _requiredProductList = [];

      Object.keys(offerOptionDictionary).map((providerService) => {
        if (offerOptionDictionary[providerService].selectedOffer) {
          const productTypeID =
            providerService.indexOf('Internet') !== -1 ? 22 : 23;

          const selectedOffer = {
            ...offerOptionDictionary[providerService].selectedOffer,
            ProductTypeID: productTypeID
          };

          let cartItem = null;
          let orderKey = null;
          let isCompleted = false;
          let fromWebSocket = false;

          if (serviceAddress.Cart) {
            cartItem = serviceAddress.Cart.filter(
              (_cartItem) =>
                _cartItem.ProductTypeID === productTypeID &&
                _cartItem.OfferID === selectedOffer.OfferID
            )[0];

            if (cartItem) {
              orderKey = cartItem.OrderKey;

              if (cartItem.IsCompleted) {
                isCompleted = true;
              }

              if (cartItem.fromWebSocket) {
                fromWebSocket = true;
                console.log('fromWebSocket 1', cartItem);
              }

              selectedOffer.cartItem = { ...cartItem };
            }
          }

          const groupingKey = getGroupingKey(selectedOffer);

          if (!_apiGrouping[groupingKey]) {
            _apiGrouping[groupingKey] = {
              providerServiceList: '',
              providerDictionary: {},
              orderPriority: 0,
              offerList: [],
              apiID:
                cartItem && cartItem.ReportedApiID
                  ? cartItem.ReportedApiID
                  : selectedOffer.ApiID,
              orderKey,
              isCompleted,
              fromWebSocket
            };
          }

          if (!cartItem) {
            _requiredProductList.push(selectedOffer);
          }

          _apiGrouping[groupingKey].offerList.push(selectedOffer);
          _apiGrouping[groupingKey].offsiteSignUpUrl =
            selectedOffer.OffsiteSignUpUrl;

          if (
            selectedOffer.OrderPriority >
            _apiGrouping[groupingKey].orderPriority
          ) {
            _apiGrouping[groupingKey].orderPriority =
              selectedOffer.OrderPriority;
          }

          _apiGrouping[groupingKey].providerServiceList +=
            (_apiGrouping[groupingKey].providerServiceList ? ' & ' : '') +
            providerService;

          if (
            !_apiGrouping[groupingKey].providerDictionary[
              selectedOffer.ProviderName
            ]
          ) {
            _apiGrouping[groupingKey].providerDictionary[
              selectedOffer.ProviderName
            ] = [];
          }

          if (selectedOffer.ApiID && !selectedOffer.SeparateOrderForEachOffer) {
            _apiGrouping[groupingKey].ApiID = selectedOffer.ApiID;
          } else {
            _apiGrouping[groupingKey].ProviderName = selectedOffer.ProviderName;
          }

          _apiGrouping[groupingKey].providerDictionary[
            selectedOffer.ProviderName
          ].push(selectedOffer);
        }
      });

      if (Object.keys(_apiGrouping).length > 0) {
        //Set the productType for each group
        Object.keys(_apiGrouping).map((groupingKey) => {
          _apiGrouping[groupingKey].productTypeID =
            _apiGrouping[groupingKey].providerServiceList.indexOf(
              'Internet'
            ) !== -1
              ? 22
              : 23;
        });

        setApiGrouping(_apiGrouping);
      }

      if (!requiredProductList) {
        setRequiredProductList(_requiredProductList);
      }
    }
  }, [offerOptionDictionary]);

  useEffect(() => {
    if (apiGrouping && !selectedGrouping) {
      setSelectedGrouping(getApiGroupingList()[0]);
    }

    console.log('apiGrouping', apiGrouping);
  }, [apiGrouping]);

  useEffect(() => {
    console.log('requiredProductList', requiredProductList);
    if (requiredProductList && requiredProductList.length) {
      //Make sure an order exists for each offer
      saveNextOrder();
    }
  }, [requiredProductList]);

  useEffect(() => {
    if (!personalInfo) {
      let personalDetailsFound = false;
      let _personalInfo = {};
      const cookieDetails = dataApi.getCookie('mi-pi');
      if (cookieDetails) {
        try {
          _personalInfo = JSON.parse(cookieDetails);
          personalDetailsFound = true;
          setPersonalInfo({ ..._personalInfo });
        } catch (e) {}
      }

      if (!personalDetailsFound) {
        setPersonalInfo({
          BillingAddressOption: 'Same'
        });
      }
    }
  }, []);

  function getGroupingKey(offer) {
    const apiID =
      offer.cartItem && offer.cartItem.ReportedApiID
        ? offer.cartItem.ReportedApiID
        : offer.ApiID;
    if (apiID && !offer.SeparateOrderForEachOffer) {
      return 'api-' + apiID;
    } else {
      return 'provider-' + offer.ProviderID;
    }
  }

  function getApiGroupingList() {
    let _apiGroupingList = Object.keys(apiGrouping).map((groupingKey) => {
      return {
        groupingKey,
        orderPriority: apiGrouping[groupingKey].orderPriority
      };
    });

    _apiGroupingList.sort((a, b) => {
      let valueA = parseFloat(a.orderPriority);
      let valueB = parseFloat(b.orderPriority);

      let result = valueA > valueB ? 1 : valueA < valueB ? -1 : 0;

      if (valueB === null) {
        result = -1;
      }

      if (valueA === null) {
        result = 1;
      }

      return result * -1;
    });

    return _apiGroupingList.map(
      (apiGroupingPriority) => apiGroupingPriority.groupingKey
    );
  }

  function getOrderStatus(groupingKey) {
    if (serviceAddress && serviceAddress.Cart) {
      var cartItem = serviceAddress.Cart.filter(
        (_cartItem) =>
          _cartItem.ProductTypeID === apiGrouping[groupingKey].productTypeID &&
          _cartItem.OrderStatusID > 1
      )[0];

      if (cartItem) {
        return cartItem.OrderStatusName;
      }
    }

    //If no cart item was found, determine status by whether it's an offsite order
    return apiGrouping[groupingKey].orderPriority === 1
      ? apiGrouping[groupingKey].offsiteUrlClicked
        ? 'In Progress'
        : 'Up Next'
      : groupingKey === selectedGrouping
      ? 'In Progress'
      : 'Up Next';
  }

  function getSaraPlusDTP(groupingKey) {
    // if (apiGrouping[groupingKey]) {
    //   if (
    //     apiGrouping[groupingKey].providerServiceList.indexOf('Internet') !==
    //       -1 &&
    //     apiGrouping[groupingKey].providerServiceList.indexOf('TV')
    //   ) {
    //     return 'internet';
    //   } else if (
    //     apiGrouping[groupingKey].providerServiceList.indexOf('Internet') !== -1
    //   ) {
    //     return 'internetx';
    //   } else {
    //     return 'video-atvx';
    //   }
    // }

    if (apiGrouping[groupingKey] && apiGrouping[groupingKey].offerList.length) {
      return apiGrouping[groupingKey].productTypeID === 22
        ? '&dtp=internetx&internetPackage=' +
            apiGrouping[groupingKey].offerList[0].ProviderOfferCode1
        : '&dtp=video-atvx&videoPackage=' +
            apiGrouping[groupingKey].offerList[0].ProviderOfferCode1;
    }

    return '';
  }

  function storeCartItem(cartItem) {
    if (cartItem) {
      let _apiGrouping = { ...apiGrouping };
      Object.keys(_apiGrouping).map((groupingKey) => {
        let _offer = _apiGrouping[groupingKey].offerList.filter(
          (offer) => offer.ProductTypeID === cartItem.ProductTypeID
        )[0];
        if (_offer) {
          _apiGrouping[groupingKey].offerList = _apiGrouping[
            groupingKey
          ].offerList.map((offer) =>
            offer.ProductTypeID === _offer.ProductTypeID
              ? { ...offer, cartItem }
              : { ...offer }
          );

          Object.keys(_apiGrouping[groupingKey].providerDictionary).map(
            (providerName) => {
              if (providerName === _offer.ProviderName) {
                _apiGrouping[groupingKey].providerDictionary[providerName] =
                  _apiGrouping[groupingKey].providerDictionary[
                    providerName
                  ].map((offer) =>
                    offer.ProductTypeID === _offer.ProductTypeID
                      ? { ...offer, cartItem }
                      : { ...offer }
                  );
              }
            }
          );
        }

        if (cartItem.IsCompleted) {
          _apiGrouping[groupingKey].isCompleted = true;
        }

        if (cartItem.fromWebSocket) {
          _apiGrouping[groupingKey].fromWebSocket = true;
          console.log('fromWebSocket 2', cartItem);
        }

        if (cartItem.OrderKey) {
          _apiGrouping[groupingKey].orderKey = cartItem.OrderKey;
        }
      });

      setApiGrouping({ ..._apiGrouping });

      //Update API grouping
      setRequiredProductList(
        requiredProductList.filter(
          (offer) => offer.ProductTypeID !== cartItem.ProductTypeID
        )
      );
    }
  }

  function saveNextOrder() {
    if (requiredProductList && requiredProductList.length) {
      const offer = requiredProductList[0];
      const existingCartItem = serviceAddress.Cart.filter(
        (cartItem) =>
          cartItem.ProductTypeID === offer.ProductTypeID &&
          cartItem.OfferID === offer.OfferID
      )[0];

      if (existingCartItem) {
        storeCartItem(existingCartItem);
      } else {
        actions
          .saveOrder(
            serviceAddress.Address.Address,
            serviceAddress.Address.Secondary,
            serviceAddress.Address.City,
            serviceAddress.Address.State,
            serviceAddress.Address.Zip,
            null,
            offer.ProviderID,
            offer.OfferID,
            1,
            null,
            null,
            null,
            null,
            offer.ProductTypeID,
            offer,
            true,
            false
          )
          .then((result) => {
            const cartItem = result.UpdatedCart
              ? result.UpdatedCart.filter(
                  (_cartItem) =>
                    _cartItem.ProductTypeID === offer.ProductTypeID &&
                    _cartItem.OfferID === offer.OfferID
                )[0]
              : null;

            if (cartItem) {
              storeCartItem(cartItem);
            }
          });
      }
    }
  }

  function useCheckoutForm(groupingKey) {
    switch (groupingKey) {
      case 'api-101':
      case 'api-102':
        return true;
      default:
        return false;
    }
  }

  function getIBEXUrl(offerGroup) {
    let url = 'https://token.bundledealer.com/?leadsourcecode=';

    const providerID =
      offerGroup.offerList && offerGroup.offerList.length
        ? offerGroup.offerList[0].ProviderID
        : 0;

    //Change the
    switch (providerID) {
      case 85634: //Astound
        url += 'ASTOUNDMOVININNPABL&apikey=prod-7sW2y6xJhzqIhJF7Johu7Q==';
        break;
      case 457: //Cox
        url += 'COXLCABB&apikey=prod-ssWbXwn6yPvOtR6ZETXJMw==';
        break;
      default:
        url += 'MOVINGINLLCKPAAQ&apikey=prod-fYUE5B0n1NkMngyKAarc1g==';
        break;
    }

    url +=
      '&addr=' +
      encodeURIComponent(serviceAddress.Address.Address) +
      '&apt=' +
      (serviceAddress.Address.Secondary
        ? encodeURIComponent(serviceAddress.Address.Secondary)
        : '') +
      '&city=' +
      encodeURIComponent(serviceAddress.Address.City) +
      '&state=' +
      encodeURIComponent(serviceAddress.Address.State) +
      '&zip=' +
      encodeURIComponent(serviceAddress.Address.Zip) +
      '&style=blue&' +
      (personalInfo && personalInfo.Phone
        ? '&phone=' + personalInfo.Phone
        : '') +
      '&movedate=' +
      (serviceAddress.Address.DefaultStartDate
        ? moment
            .utc(serviceAddress.Address.DefaultStartDate)
            .format('YYYY-MM-DD')
        : '') +
      '&agentid=007&prevadd=' +
      (serviceAddress.Address.Previous_Address
        ? encodeURIComponent(serviceAddress.Address.Previous_Address)
        : '') +
      '&prevadd2=' +
      (serviceAddress.Address.Previous_Secondary
        ? encodeURIComponent(serviceAddress.Address.Previous_Secondary)
        : '') +
      '&prevzip=' +
      (serviceAddress.Address.Previous_Zip
        ? encodeURIComponent(serviceAddress.Address.Previous_Zip)
        : '') +
      '&ordersource=online&hidetoaster=true&enableca=0&hidesid=1&hideagid=1&hideheader=1&hidefooter=1&returnurl=https://movingin.com/dashboard';

    return url;
  }

  return (
    <div className="page-container checkout-container internet product-type-1 undocked internet-hud-container">
      {selectedSection ? <h1>{selectedSection.Title}</h1> : null}

      {selectedSection && selectedSection.IntroText ? (
        <IntroText
          trackAs="internet-checkout"
          text={selectedSection.IntroText}
          mobileText={selectedSection.IntroTextMobile}
        />
      ) : null}

      {apiGrouping ? (
        <>
          <div className="internet-hud">
            <ul>
              {getApiGroupingList().map((groupingKey, groupingIndex) => (
                <li
                  className={groupingKey === selectedGrouping ? 'active' : ''}>
                  {/* <div className="direction">
                    {groupingIndex === 0
                      ? 'Your MovingIN Game Plan'
                      : 'Next, Your ' +
                        apiGrouping[groupingKey].providerServiceList +
                        ' Order'}
                  </div>

                  <div className="connector"></div> */}

                  <div
                    className={
                      'card' +
                      (useCheckoutForm(groupingKey) &&
                      apiGrouping[groupingKey].offerList[0].cartItem
                        ? ' ready'
                        : '')
                    }>
                    <div id="checkoutHeaderIframe" className="card-header">
                      <h2>
                        {!apiGrouping[groupingKey].isCompleted ? (
                          <Button
                            onClick={(event) => {
                              navigate('/plan-builder');
                              event.stopPropagation();
                            }}>
                            Change Plan
                          </Button>
                        ) : null}
                        {groupingIndex === 0
                          ? (!apiGrouping[groupingKey].isCompleted
                              ? 'Complete '
                              : '') +
                            'Your ' +
                            apiGrouping[groupingKey].providerServiceList +
                            ' Order'
                          : 'Selected ' +
                            apiGrouping[groupingKey].providerServiceList +
                            ' Plan'}
                      </h2>

                      {Object.keys(
                        apiGrouping[groupingKey].providerDictionary
                      ).map((providerName, providerIndex) => (
                        <div>
                          {apiGrouping[groupingKey].providerDictionary[
                            providerName
                          ].map((offer) => (
                            <ProviderLogo
                              offer={
                                offer.cartItem &&
                                offer.cartItem.ReportedProviderID
                                  ? {
                                      ...offer,
                                      ProviderName:
                                        offer.cartItem.ReportedProviderName,
                                      LogoFile:
                                        offer.cartItem.ReportedProviderLogoFile
                                    }
                                  : { ...offer }
                              }
                            />
                          ))}
                          <div className="offer-name">
                            {apiGrouping[groupingKey].providerDictionary[
                              providerName
                            ].map(
                              (offer, offerIndex) =>
                                (offerIndex > 0 ? ' + ' : '') +
                                (!offer.cartItem ||
                                !offer.cartItem.ProviderOfferName
                                  ? offer.DisplayName
                                  : offer.cartItem.ProviderOfferName)
                            )}
                          </div>
                          {apiGrouping[groupingKey].providerDictionary[
                            providerName
                          ]
                            .filter(
                              (offer) =>
                                offer.cartItem &&
                                (offer.cartItem.ProviderAccountNumber ||
                                  offer.cartItem.ProviderConfirmationNumber ||
                                  offer.cartItem.ProviderOrderStatus)
                            )
                            .map((offer) => (
                              <div className="provider-order-detail">
                                {offer.cartItem.ProviderAccountNumber ? (
                                  <div>
                                    <label>Account Number</label>
                                    {offer.cartItem.ProviderAccountNumber}
                                  </div>
                                ) : null}

                                {offer.cartItem.ProviderConfirmationNumber ? (
                                  <div>
                                    <label>Order Number</label>
                                    {offer.cartItem.ProviderConfirmationNumber}
                                  </div>
                                ) : null}

                                {offer.cartItem.ProviderOrderStatus ? (
                                  <div>
                                    <label>Order Status</label>
                                    {offer.cartItem.ProviderOrderStatus}
                                  </div>
                                ) : null}
                              </div>
                            ))}
                        </div>
                      ))}

                      {apiGrouping[groupingKey].offerList.filter(
                        (offer) =>
                          offer.cartItem && offer.cartItem.ProviderOrderStatus
                      ).length === 0 ? (
                        <div className="status">
                          Order Status:{' '}
                          <strong>{getOrderStatus(groupingKey)}</strong>
                        </div>
                      ) : null}
                    </div>

                    {useCheckoutForm(groupingKey) ? (
                      apiGrouping[groupingKey].offerList[0].cartItem ? (
                        <div className="checkout-form-outer">
                          <CheckoutForm
                            productTypeID={
                              apiGrouping[groupingKey].productTypeID
                            }
                            productTypeName={
                              apiGrouping[groupingKey].providerServiceList
                            }
                            modal={modal}
                            uiControl={uiControl}
                            setUiControl={setUiControl}
                          />
                        </div>
                      ) : (
                        <LinearProgress />
                      )
                    ) : apiGrouping[groupingKey].apiID === 100 &&
                      apiGrouping[groupingKey].offerList[0].ProviderID ===
                        85472 &&
                      apiGrouping[groupingKey].offerList[0]
                        .IsExistingCustomer ? (
                      <div className="active-customer-check">
                        <Alert severity="warning">
                          This address currently has an active{' '}
                          {apiGrouping[groupingKey].offerList[0].ProviderName}{' '}
                          account associated with it.
                          <br />
                          <br />
                          If you are moving to this address and want to set up
                          new{' '}
                          {
                            apiGrouping[groupingKey].offerList[0].ProviderName
                          }{' '}
                          service in your name, please call{' '}
                          <a
                            href={
                              'tel:' +
                              apiGrouping[groupingKey].offerList[0].OrderPhone
                            }
                            className="link">
                            {apiGrouping[groupingKey].offerList[0].OrderPhone}
                          </a>{' '}
                          to complete your order.
                        </Alert>
                      </div>
                    ) : apiGrouping[groupingKey].apiID === 100 &&
                      apiGrouping[groupingKey].offerList[0].ProviderID ===
                        85472 &&
                      activeCustomerSelection !== 'No' ? (
                      <div className="active-customer-check">
                        <div className="content-area internet-move-or-switch">
                          <h4>
                            Step 1: Please choose whether you are a new or
                            existing{' '}
                            {apiGrouping[groupingKey].offerList[0].ProviderName}{' '}
                            customer:
                          </h4>

                          <FormControl
                            component="fieldset"
                            className="service-order-type">
                            <RadioGroup
                              aria-label="serviceOrderType"
                              name="serviceOrderType"
                              value={activeCustomerSelection}
                              onChange={(event, newValue) => {
                                setActiveCustomerSelection(newValue);
                              }}>
                              <FormControlLabel
                                value="No"
                                className={
                                  activeCustomerSelection === 'No'
                                    ? 'selected'
                                    : ''
                                }
                                control={<Radio color="primary" />}
                                label={
                                  <>
                                    <strong>New Customer:</strong> I am starting
                                    new service with{' '}
                                    {
                                      apiGrouping[groupingKey].offerList[0]
                                        .ProviderName
                                    }
                                    .
                                  </>
                                }
                              />

                              <FormControlLabel
                                value="Yes"
                                className={
                                  activeCustomerSelection === 'Yes'
                                    ? 'selected'
                                    : ''
                                }
                                control={<Radio color="primary" />}
                                label={
                                  <>
                                    <strong>Current Customer:</strong> I already
                                    have an account with{' '}
                                    {
                                      apiGrouping[groupingKey].offerList[0]
                                        .ProviderName
                                    }{' '}
                                    and I am transferring service to a new
                                    address.
                                  </>
                                }
                              />
                            </RadioGroup>
                          </FormControl>

                          {activeCustomerSelection === 'Yes' ? (
                            <Alert severity="warning">
                              If you are a current{' '}
                              {
                                apiGrouping[groupingKey].offerList[0]
                                  .ProviderName
                              }{' '}
                              customer, please call{' '}
                              <a
                                href={
                                  'tel:' +
                                  apiGrouping[groupingKey].offerList[0]
                                    .OrderPhone
                                }
                                className="link">
                                {
                                  apiGrouping[groupingKey].offerList[0]
                                    .OrderPhone
                                }
                              </a>{' '}
                              to complete your order over the phone.
                            </Alert>
                          ) : null}
                        </div>
                      </div>
                    ) : apiGrouping[groupingKey].apiID === 100 ||
                      apiGrouping[groupingKey].apiID === 104 ? (
                      selectedGrouping === groupingKey ? (
                        <div className="internet-order-frame">
                          <>
                            {apiGrouping[groupingKey].isCompleted ? (
                              <div className="order-info">
                                <Alert severity="success">
                                  Your order is complete. Check your email for
                                  order details.
                                </Alert>
                              </div>
                            ) : null}

                            {!apiGrouping[groupingKey].isCompleted ? (
                              <div className="internet-iframe">
                                {!iFrameLoaded ? (
                                  <div className="iframe-loading">
                                    <LinearProgress />
                                    <span className="message">
                                      Loading{' '}
                                      {
                                        apiGrouping[groupingKey]
                                          .providerServiceList
                                      }{' '}
                                      checkout...
                                    </span>
                                  </div>
                                ) : null}
                                <iframe
                                  id="iframe_api-100"
                                  width="100%"
                                  height={iFrameLoaded}
                                  scrolling="auto"
                                  frameborder="0"
                                  marginheight="0px"
                                  marginwidth="0px"
                                  src={
                                    apiGrouping[groupingKey].apiID === 104
                                      ? getIBEXUrl(apiGrouping[groupingKey])
                                      : (typeof window !== 'undefined' &&
                                        window.location.host.indexOf(
                                          'localhost'
                                        ) === -1 &&
                                        window.location.host.indexOf(
                                          'stage.'
                                        ) === -1
                                          ? 'https://www.dsispoc.com/?key=Sd2ZWfA4N4z39gea'
                                          : 'https://qa.saraplus.com/e/oe/?key=Sd2ZWfA4N4z39gea') +
                                        '&streetAddress=' +
                                        encodeURIComponent(
                                          serviceAddress.Address.Address
                                        ) +
                                        (serviceAddress.Address.Secondary
                                          ? '&aptUnitNum=' +
                                            (serviceAddress.Address.Secondary
                                              ? encodeURIComponent(
                                                  serviceAddress.Address
                                                    .Secondary
                                                )
                                              : '')
                                          : '') +
                                        '&city=' +
                                        encodeURIComponent(
                                          serviceAddress.Address.City
                                        ) +
                                        '&state=' +
                                        encodeURIComponent(
                                          serviceAddress.Address.State
                                        ) +
                                        '&zip=' +
                                        encodeURIComponent(
                                          serviceAddress.Address.Zip
                                        ) +
                                        '&accountNum=' +
                                        userAuthApi.getVisitInfo().VisitorKey +
                                        '&orderNum=' +
                                        userAuthApi.getVisitInfo().VisitKey +
                                        (apiGrouping[groupingKey].orderKey
                                          ? '&refnum=' +
                                            apiGrouping[groupingKey].orderKey
                                          : '') +
                                        (personalInfo && personalInfo.FirstName
                                          ? '&firstName=' +
                                            personalInfo.FirstName
                                          : '') +
                                        (personalInfo && personalInfo.LastName
                                          ? '&lastName=' + personalInfo.LastName
                                          : '') +
                                        (personalInfo && personalInfo.Email
                                          ? '&email=' + personalInfo.Email
                                          : '') +
                                        (personalInfo && personalInfo.Phone
                                          ? '&phone=' + personalInfo.Phone
                                          : '') +
                                        getSaraPlusDTP(groupingKey)
                                  }
                                  onLoad={() => {
                                    let _iframeHeight = 1550;

                                    try {
                                      _iframeHeight =
                                        document.getElementById(
                                          'iframe_api-100'
                                        ).contentWindow.document.body
                                          .scrollHeight + 50;
                                    } catch (e) {}

                                    setIFrameLoaded(_iframeHeight);

                                    dataApi.scrollToElementInPage(
                                      document.getElementById(
                                        'checkoutHeaderIframe'
                                      ),
                                      -100,
                                      true
                                    );
                                  }}></iframe>
                              </div>
                            ) : null}
                          </>
                        </div>
                      ) : null
                    ) : (
                      <div className="offsite-order">
                        <Alert severity="info">
                          Your {apiGrouping[groupingKey].providerServiceList}{' '}
                          order must be completed at the{' '}
                          {apiGrouping[groupingKey].ProviderName} website.{' '}
                          <a
                            href={apiGrouping[groupingKey].offsiteSignUpUrl}
                            target="mi_offsite"
                            onClick={() => {
                              actions.saveOrder(
                                serviceAddress.Address.Address,
                                serviceAddress.Address.Secondary,
                                serviceAddress.Address.City,
                                serviceAddress.Address.State,
                                serviceAddress.Address.Zip,
                                null,
                                apiGrouping[groupingKey].offerList[0]
                                  .ProviderID,
                                apiGrouping[groupingKey].offerList[0].OfferID,
                                2,
                                apiGrouping[groupingKey].offsiteSignUpUrl,
                                null,
                                null,
                                'Product',
                                apiGrouping[groupingKey].productTypeID
                              );
                              setApiGrouping({
                                ...apiGrouping,
                                [groupingKey]: {
                                  ...apiGrouping[groupingKey],
                                  offsiteUrlClicked: true
                                }
                              });
                            }}
                            className="link">
                            Click here
                          </a>{' '}
                          to go to the {apiGrouping[groupingKey].ProviderName}{' '}
                          website and process your order. Be sure to come back
                          here when you are done to complete your checkout.
                        </Alert>
                      </div>
                    )}
                  </div>
                  {/* 
                  <div className="connector"></div> */}
                </li>
              ))}
            </ul>

            {/* <Dashboard
              continueFromServiceSection={2}
              continueTitle={
                Object.keys(apiGrouping).filter(
                  (groupingKey) => apiGrouping[groupingKey].orderPriority === 1
                ).length
                  ? 'When You Return'
                  : (apiGrouping &&
                    apiGrouping[selectedGrouping] &&
                    apiGrouping[selectedGrouping].isCompleted
                      ? 'Now, '
                      : 'Then, ') + 'Continue Your Checklist'
              }
            /> */}
          </div>
        </>
      ) : null}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    webSocket: state.webSocket,
    serviceAddress: state.serviceAddress,
    stateList: state.commonData.stateList,
    siteConfig: state.commonData.siteConfig
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      pageLoading: bindActionCreators(webSocketActions.pageLoading, dispatch),
      saveOrder: bindActionCreators(serviceAddressActions.saveOrder, dispatch)
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutInternet);
